const columns = [
    {
        Header: 'Bezeichnung',
        accessor: 'name'
    },
    {
        Header: '',
        accessor: 'id'
    },
]
export default columns;