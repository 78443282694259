/******************************************************************************
 *                                                                            *
 *  GroupPage.js                                                              *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 21.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import columns from "./group_columns.js";
import "./GroupPage.css";
import FoodListPage from "../pages/FoodListPage";

const GroupPage = (props) => {
    const {t, foodListId} = props;

    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({});

    // Holds info about the foodlist
    const [foodListData, setFoodListData] = React.useState([]);

    const retrieveFoodListData = () => {
        Context.api.get('foodList/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodListData)) {
                setFoodListData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    const retrieveGroups = () => {
        Context.api.get('allFoodGroups/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
                setData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    useEffect(() => {
        retrieveFoodListData();
        retrieveGroups();
    }, [] );

    const editGroup = (id) => {
        if ( id === 0 ) {
            setDlgData( { id: 0 } );
        } else {
            Context.api.get("foodGroup/"+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
                res.data.picture = null;
                setDlgData( res.data );
            }).catch( ( error ) => {
                Context.alertError( error );
            });
        }
    }
        
    const saveGroup = () => {
        let formData = new FormData()
        formData.append('id', dlgData.id )
        formData.append('flid', foodListId )

        if ( !dlgData.name ) {
            alert( "Bitte eine Bezeichnung eintragen!");
            return;
        }
        formData.append('name', dlgData.name )

        if (dlgData.pictureData != null ) {
            formData.append('image', dlgData.pictureData )
            Context.api.post("saveFoodGroup", formData, 
            { headers: {
                'content-type': false
            }, auth: {
	                username: Context.username,
	                password: Context.password
	        }}).then( () => {
                retrieveGroups();
                setDlgData({});
            }).catch( ( error ) => {
                Context.alertError( error );
            });
        } else {
            Context.api.post("saveFoodGroupWithoutImage", formData, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
                retrieveGroups();
                setDlgData({});
            }).catch( ( error ) => {
				Context.alertError( error );
            });
        }
    }
        
    const deleteGroup = (id) => {
        if (window.confirm('Eintrag wirklich löschen?')) {
            Context.api.delete('deleteFoodGroup/'+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
                retrieveGroups();
                setDlgData({});
            }).catch( ( error ) => {
                Context.alertError( error );
            });
        }
    }

    const showFoodPage = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });
  
    return(
        <>
            <div id='divGroupTitle'>Lebensmittel-Gruppen</div>
            <div className='divGroupTable'>
            
                {/* New/Edit-Dialog */}
                <Modal id='dlgEditGroup' isOpen={dlgData.id != null}>
                    Neue Lebensmittel-Gruppe
                    <hr/>
                    <br/>
                    
                    Bezeichnung:<br/>
                    <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/><br/>
                    <br/>
                    Bild (wenn kein neues ausgewählt wird, bleibt das alte erhalten):<br/>
                    <input type="file" value={dlgData.picture} accept="image/gif" onChange={e => {
                        setDlgData( Object.assign( {}, dlgData, {picture : e.target.value}, {pictureData : e.target.files[0]}))}
                    } />
                    <br/>
                    <input type='hidden' maxlength='500' value={dlgData.id} />
                    <br/>
                    
                    <div className='dlgBtns'>
                        <input className='btn' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveGroup()} />
                        <input className='btn' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({})} />
                    </div>
                </Modal>
                
                <div id='butnDiv'>
                    <div id='butnDivL'>
                        <input className='btn' type='image' src='/pics/new.png' title='Neue Gruppe' alt='Neue Gruppe' onClick={() => editGroup(0)} />
                    </div>
                    <div id='butnDivR'>
                        <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodPage()} />
                    </div>
                </div>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell,j) => {
                                if ( j === row.cells.length- 1 ) {
                                    return( 
                                        <td {...cell.getCellProps()}>
                                            <input className='btn' type='image' src='/pics/edit.png' title='Bearbeiten' alt='Bearbeiten' onClick={() => editGroup(cell.value)} />
                                            <input className='btn' type='image' src='/pics/delete.png' title='Löschen' alt='Löschen' onClick={() => deleteGroup(cell.value)} />
                                        </td>
                                    );
                                } else if ( j === row.cells.length- 2 ) {
                                    return(
                                        <td {...cell.getCellProps()}>
                                            <img height='60' alt='' src={"data:image/gif;base64," + cell.value} />
                                        </td>
                                    );                                    
                                } else {
                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
                                }
                                })}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default GroupPage;