/******************************************************************************
 *                                                                            *
 *  DeleteAccountPage.js                                                      *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './DeleteAccountPage.css'
import Context from "../context";
import { withTranslation } from 'react-i18next';

const DeleteAccountPage = (props) => {
    const { t } = props;

    const sendDeletionEMail = () => {

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            alert( t("INVALID_MAILFORMAT"))
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            email        : email,
            activationkey: activationKey,
            mailSubject  : t("ACCOUNT_DELETION"),
            mailBody     : t("MAIL_BODY_DELETION", { "LINK": Context.frontendURL+ "deleteAccount?activationKey="+ activationKey })
        };
        Context.api.post('preparePasswordReset', o ).then( res => {
            alert( t("MAIL_HAS_BEEN_SENT") );
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div className="stddlg-outer">
            <div className="stddlg">
                <div dangerouslySetInnerHTML={{__html: t("MESSAGE", { "USERNAME": Context.username })}} />
                <br/>
                <br/>
                <form >
                    {t("ENTER_USED_EMAIL")}:
                    <br/>
                    <br/>
                    <p>
                        {t("MAIL_ADDRESS")}: <input type="text" name="email" id="email" required/>
                    </p>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => sendDeletionEMail() } value={t("SEND_DELETION_MAIL")}/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['deleteAccountPage', 'common'])(DeleteAccountPage);
