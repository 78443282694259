/******************************************************************************
 *                                                                            *
 *  Navbar.js                                                                 *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 26.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect, Suspense } from "react";
import Cookies from "js-cookie";
import './Navbar.css'
import ReactDOM from "react-dom";
import Context from "../context";
import {withTranslation} from "react-i18next";
import i18n from '../i18n';

import HomePage from "../pages/HomePage";
import RegisterPage from "../pages/RegisterPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import ProfilePage from "../pages/ProfilePage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import LoginPage from "../pages/LoginPage";
import DeleteAccountPage from "../pages/DeleteAccountPage";
import ImprintPage from "../pages/ImprintPage";
import DSGVOPage from "../pages/DSGVOPage";

const Navbar = (props) => {
    const { t } = props;

    const [clicked, setClicked] = React.useState(false); // for the burger-menu show and hide
    const [menuitems, setMenuitems] = React.useState([]);
    const [user, setUser] = React.useState(Context.username);

    useEffect(() => {
        i18n.changeLanguage( Context.language );
        if ( Context.username === "" ) {
            Context.api.get('retrieveMainMenu').then( res => {
                setClicked(clicked);
                setMenuitems( res.data );
            }).catch( ( error ) => {
                Context.alertError( error );
            })
        } else {
            Context.api.get('retrieveMainMenu', {
                auth: {
                    username: Context.username,
                    password: Context.password
                }}).then( res => {
                setClicked(clicked);
                setMenuitems( res.data );
            }).catch( () => {
                // Maybe the browser had hold an old login -> reset and try again
                Cookies.set( 'user', '' );
                Cookies.set( 'pw', '' );
                Context.username = '';
                Context.password = '';
                Context.api.get('retrieveMainMenu').then( res => {
                setClicked(clicked);
                setMenuitems( res.data );
                }).catch( ( error ) => {
                    Context.alertError( error );
                })
            });
        }
    }, [user] );

    // Toggle the burger-menu
    const handleClick = () => {
        setClicked(!clicked);
    }

    const showSubmenu = ( event ) => {
        if ( document.getElementById( "submenu"+ event.target.id ) != null ) {
            document.getElementById( "submenu"+ event.target.id ).style.display = "block";
        } else {
            if ( document.getElementById( event.target.id ) != null ) {
                document.getElementById( event.target.id ).style.display = "block";
            }
        }
    }

    const hideSubmenu = ( event ) => {
        if ( document.getElementById( "submenu"+ event.target.id ) != null ) {
            document.getElementById("submenu" + event.target.id).style.display = "none";
        } else {
            if ( document.getElementById( event.target.id ) != null ) {
                document.getElementById(event.target.id).style.display = "none";
            }
        }
    }

    const doLogoff = () => {
        Context.username = "";
        Context.password = "";
        Cookies.set( 'user', "" );
        Cookies.set( 'pw', "" );
        setUser("");
        ReactDOM.render(
            <div id="divLoginOuter" className="stddlg-outer"><div id="divLogin" className="stddlg">
                <span>Du wurdest abgemeldet. Bis bald :-)</span>
            </div></div>, document.getElementById('pageArea'));
    }

    const doit = ( event ) => {
        if ( event.currentTarget.name ) {
            handleClick();
            hideSubmenu(event);
            execute[event.currentTarget.name]();
        }
    }

    const showHomePage = () => {
        ReactDOM.render( <Suspense fallback="loading"><HomePage /></Suspense>, document.getElementById('pageArea'));
    }

    const showLoginPage = () => {
        ReactDOM.render( <Suspense fallback="loading"><LoginPage showHomePage={showHomePage} setUser={setUser}/></Suspense>, document.getElementById('pageArea'));
    }

    const showRegisterPage = () => {
        ReactDOM.render( <Suspense fallback="loading"><RegisterPage showHomePage={showHomePage}/></Suspense>, document.getElementById('pageArea'));
    }

    const showPasswordResetPage = () => {
        ReactDOM.render( <Suspense fallback="loading"><PasswordResetPage showHomePage={showHomePage}/></Suspense>, document.getElementById('pageArea'));
    }

    const showProfilePage = () => {
        ReactDOM.render( <Suspense fallback="loading"><ProfilePage /></Suspense>, document.getElementById('pageArea'));
    }

    const showChangePasswordPage = () => {
        ReactDOM.render( <Suspense fallback="loading"><ChangePasswordPage showHomePage={showHomePage}/></Suspense>, document.getElementById('pageArea'));
    }

    const showDeleteAccountPage = () => {
        ReactDOM.render( <Suspense fallback="loading"><DeleteAccountPage showHomePage={showHomePage}/></Suspense>, document.getElementById('pageArea'));
    }

    const showImprint = () => {
        ReactDOM.render( <Suspense fallback="loading"><ImprintPage /></Suspense>, document.getElementById('pageArea'));
    }

    const showDSGVO = () => {
        ReactDOM.render( <Suspense fallback="loading"><DSGVOPage /></Suspense>, document.getElementById('pageArea'));
    }

    const execute = {
        showHomePage : showHomePage,
        showLoginPage : showLoginPage,
        showRegisterPage : showRegisterPage,
        showPasswordResetPage : showPasswordResetPage,
        showProfilePage : showProfilePage,
        showChangePasswordPage : showChangePasswordPage,
        showDeleteAccountPage : showDeleteAccountPage,
        doLogoff : doLogoff,
        showImprint : showImprint,
        showDSGVO : showDSGVO
    };

    return(
        <nav className="NavbarItems">
            <div className="menu-icon" onClick={handleClick}>
                <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul className={clicked ? 'nav-menu active' : 'nav-menu'}> {
                menuitems.map((item,index) => {
                    return (
                        <li id={index} key={index}>
                            <a id={index} className={item.cName} onClick={doit} name={item.url} onMouseEnter={showSubmenu} onMouseLeave={hideSubmenu}>
                                <span className={item.image} />
                                {t(item.title)}
                            </a>
                            <div id={"submenu"+index} className={clicked ? 'nav-submenu-div active' : 'nav-submenu-div'}
                                    onMouseEnter={showSubmenu} onMouseLeave={hideSubmenu}>
                                <ul id={index} className='nav-submenu'> {
                                    item.submenu.map((subitem,subindex) => {
                                        return (
                                            <li id={index} key={index + subindex}>
                                                <a id={index} className={subitem.cName} onClick={doit} name={subitem.url}>
                                                    <span id={index} className={subitem.image} />
                                                    {t(subitem.title)}
                                                </a>
                                            </li>
                                        )
                                    })
                                } </ul>
                            </div>
                        </li>
                    )
                })
            } </ul>
        </nav>
    );
}

export default withTranslation('common' )(Navbar);
