/******************************************************************************
 *                                                                            *
 *  exceptiond.js                                                             *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 17.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React from "react";

var serverException = new Map();

serverException.set( "1", "USERNAME_ALREADY_EXISTS" );
serverException.set( "2", "EMAILADDRESS_ALREADY_USED" );
serverException.set( "3", "INVALID_ACTIVATIONKEY" );
serverException.set( "4", "ACCOUT_NOT_ENABLED" );
serverException.set( "5", "UNKNOWN_USERNAME" );
serverException.set( "6", "WRONG_PASSWORD" );
serverException.set( "7", "ACCOUNT_HAS_BEEN_LOCKED" );
serverException.set( "8", "ACCOUNT_IS_LOCKED" );
serverException.set( "9", "UNKNOWN_EMAILADDRESS" );
serverException.set( "10", "PW_NOT_CHANGEABLE" );
serverException.set( "11", "DOUBLE_BITNUM" );

export default serverException
