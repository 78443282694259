const columns = [
    {
        Header: 'Bezeichnung',
        accessor: 'name'
    },
    {
        Header: '<image width="16" src="/pics/mikrowelle_weiss.png" title="Essfertig" alt="Essfertig" />',
        accessor: 'readytoeat'
    },
    {
        Header: 'Gruppe',
        accessor: 'foodgroup'
    },
    {
        Header: 'Gefäß',
        accessor: 'container'
    },
    {
        Header: 'Haltbarkeit',
        accessor: 'shelftime'
    },
    {
        Header: 'Menge',
        accessor: 'amount'
    },
    {
        Header: 'Bild',
        accessor: 'picture'
    },
    {
        Header: 'EANCode',
        accessor: 'eancode'
    },
    {
        Header: '',
        accessor: 'id'
    },
]
export default columns;