/******************************************************************************
 *                                                                            *
 *  PasswordResetPage.js                                                      *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, {Component} from "react";
import './PasswordResetPage.css'
import Context from "../context";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

const PasswordResetPage = (props) => {
    const { t } = props;

    const sendResetEMail = (event) => {
        const { t } = this.props;

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            alert( t("INVALID_MAILFORMAT"))
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            email        : email,
            activationkey: activationKey,
            mailSubject  : t("PASSWORD_RESET"),
            mailBody     : t("MAIL_BODY_RESET", { "LINK": Context.frontendURL+ "resetPassword?activationKey="+ activationKey })
        };
        Context.api.post('preparePasswordReset', o ).then( res => {
            alert( t("MAIL_HAS_BEEN_SENT") );
            this.props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divPasswordResetOuter" className="stddlg-outer">
            <div id="divPasswordReset" className="stddlg">
                <form >
                    {t("ENTER_USED_EMAIL")}:
                    <p>
                        {t("MAIL_ADDRESS")}: <input type="text" name="email" id="email" required/>
                    </p>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => this.sendResetEMail() } value={t("SEND_RESET_MAIL")}/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['passwordResetPage', 'common'] )(PasswordResetPage);
