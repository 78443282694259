/******************************************************************************
 *                                                                            *
 *  Index.js                                                                  *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 05.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';

ReactDOM.render( <Suspense fallback={<div>Loading...</div>}><App /></Suspense>, document.getElementById('root'));
