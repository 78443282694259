/******************************************************************************
 *                                                                            *
 *  context.js                                                                *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 01.10.2021                                                     *
 *  modified : 31.05.2022 mw crypto.js genutzt                                *
 *                                                                            *
 *  (C) 2021-2022 Martin Wolf IT-Consulting (www.wolf-itc.de)                 *
 *                                                                            *
 * ***************************************************************************/
import {Component} from "react";
import i18n from './i18n';
import exceptions from "./exceptions";

class Context extends Component {
    static username = "";
    static password = "";
    static language = "";
    static backendURL = "";
    static api = [];

    // ---------------------------------

    static ENC_KEY = "bf3c199c2470cb477d907b1e0917c17b"; // set random encryption key
	static CryptoJS = require("crypto-js");
	
	// Encrypt
	static encrypt = (text) => {
		let ciphertext = Context.CryptoJS.AES.encrypt(text, Context.ENC_KEY).toString();
		return ciphertext;
	}
	
	// Decrypt
	static decrypt = (text) => {
		let bytes  = Context.CryptoJS.AES.decrypt(text, Context.ENC_KEY);
		let originalText = bytes.toString(Context.CryptoJS.enc.Utf8);
		return originalText;
	}

    // ---------------------------------

    static alertError(error) {
        if (error.response && error.response.data && error.response.data.ExceptionCode) {
            const errorCode = exceptions.get(error.response.data.ExceptionCode);
            const translation = i18n.t('common:'+ errorCode);
            alert(translation);
        } else {
            if ( error.response && error.response.data) {
	            alert(error+ " : "+ JSON.stringify(error.response.data));
	        } else {
	            alert(error);
	        }
        }
    }

    static generateToken(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static getQueryParams( t ) {
        if( !t || "string"!=typeof t || t.length<2 ) {
            return new Map;
        } else {
            const params = t.split("&" ).map( t => {
                const pair = t.split("=");
                return [pair[0], pair[1]]
            });
            return new Map( params );
        }
    }
}

export default Context