/******************************************************************************
 *                                                                            *
 *  NewFoodGroupSelectionPage.js                                              *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 23.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import "./NewFoodGroupSelectionPage.css";
import FoodListPage from "../pages/FoodListPage";
import NewFoodPage from "../pages/NewFoodPage";

const NewFoodGroupSelectionPage = (props) => {
    const {t, foodListId} = props;

    // Holds the foodgroups
    const [foodgroups, setFoodgroups] = React.useState([]);

    // Retrieve the foodgroups
    const retrieveFoodGroups = () => {
        Context.api.get('allFoodGroups/' + foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodgroups)) {
                setFoodgroups( res.data );
            }
        }).catch( ( error ) => {
			Context.alertError( error )
        })
    };

    useEffect(() => {
        retrieveFoodGroups();
    }, [] );

    const showFoodListPage = (id) => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showNewFoodListPage = (id) => {
        ReactDOM.render( <NewFoodPage fgid={id} foodListId={foodListId} />, document.getElementById('pageArea'));
    }

    return(
        <>
            <div id='divNewFoodGroupSelection'>
	            <div id='divChoosGroupTitle'>Lebensmittelgruppe auswählen</div>

	            <div id='butnDivR'>
	                <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodListPage()} />
	            </div>

	            <div id='divFoodgroups'>
	                {foodgroups.length? 
							foodgroups.map((foodgroup) => (
			                    <div className='divFoodgroup' onClick={() => showNewFoodListPage(foodgroup.id)}>
			                        {foodgroup.name}
			                        <br/><br/>
			                        <img height='120' alt='' src={"data:image/gif;base64," + foodgroup.picture} />
			                    </div>
			                ))
						:
							<div>Bitte zuerst eine Lebensmittelgruppe anlegen</div>
						
					}             
	            </div>
			</div>
        </>
    );
};

export default NewFoodGroupSelectionPage;