/******************************************************************************
 *                                                                            *
 *  LoginPage.js                                                              *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './LoginPage.css'
import Context from "../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';

const LoginPage = (props) => {
    const { t } = props;

    const doLogin = () => {
        const user = document.getElementById('username').value;
        if ( user === "" ) {
            alert( t("ENTER_USERNAME") );
            return;
        }

        const pw = document.getElementById('password').value;
        if ( pw === "" ) {
            alert( t("ENTER_PASSWORD"))
            return;
        }

        const o = {
            username: user,
            password: pw
        }
        Context.api.post('doLogin', o ).then( res => {
            Context.username = user;
            Context.password = pw;
            Context.language = res.data;
            const encr = Context.encrypt( pw );
            Cookies.set( 'user', user );
            Cookies.set( 'pw', encr );
            Cookies.set( 'language', res.data );
            props.setUser(user);
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divLoginOuter" className="stddlg-outer">
            <div id="divLogin" className="stddlg">
                <div>
                    <form >
                        <p>
                            {t('USERNAME')}:<br/><input className="loginfeld" type="text" name="username" id="username" required/>
                        </p>
                        <br/>
                        <p>
                            {t('PASSWORD')}:<br/><input className="loginfeld" type="password" name="password" id="password" required/>
                        </p>
                        <br/>
                        <p>
                            <input type="button" onClick={ () => doLogin() } value={t('LOGIN')}/>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default withTranslation(['loginPage', 'common'])(LoginPage);
