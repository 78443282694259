const columns = [
    {
        Header: '',
        accessor: 'verbrauchsmarker'
    },
    {
        Header: 'Bezeichnung',
        accessor: 'name'
    },
    {
        Header: '<image width="16" src="/pics/mikrowelle_weiss.png" title="Essfertig" alt="Essfertig" />',
        accessor: 'readytoeat'
    },
    {
        Header: 'Gefäß',
        accessor: 'container'
    },
    {
        Header: 'Haltbar bis',
        accessor: 'bestbefore'
    },
    {
        Header: 'Menge',
        accessor: 'amount'
    },
    {
        Header: 'Bild',
        accessor: 'picture'
    },
    {
        Header: 'Hinzugefügt',
        accessor: 'added'
    },
    {
        Header: '',
        accessor: 'id'
    },
]
export default columns;