/******************************************************************************
 *                                                                            *
 *  ChangePasswordPage.js                                                     *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import './ChangePasswordPage.css'
import Context from "../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import HomePage from "../pages/HomePage";

const ChangePasswordPage = (props) => {
    const { t } = props;

    const doChangePassword = (event) => {
        const oldPassword = document.getElementById('oldPassword').value;
        if ( oldPassword === "" ) {
            alert( i18n.t("ENTER_CURRENT_PASSWORD"));
            return;
        }

        const password = document.getElementById('password').value;
        var pwOk = true;
        if ( password.match( /[^a-zA-Z0-9#*+-~@$%&!]/ )) pwOk = false;
        if ( !password.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#|*|+|-|~|@|$|%|&])[0-9a-zA-Z#*+-~@$%&!]{8,}$/ )) pwOk = false;
        if ( !pwOk ) {
            alert( t("ENTER_VALID_PASSWORD"))
            return;
        }

        const passwordRpt = document.getElementById('passwordRpt').value;
        if ( password !== passwordRpt ) {
            alert( t("PASSWORDS_DONT_MATCH"));
            return;
        }

        var o = {
            username   : Context.username,
            oldPassword: oldPassword,
            password   : password
        };
        Context.api.post('changePassword', o, {
            auth: {
                username: Context.username,
                password: Context.password
            }} ).then( () => {
            Context.password = password;
            const encr = Context.encrypt( password );
            Cookies.set( 'pw', encr );
            alert( t("PASSWORD_HAS_BEEN_CHANGED" ));
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });

    }

    return (
        <div id="divRegisterOuter" className="stddlg-outer">
            <div id="divRegister" className="stddlg">
                <form >
                    <p>
                        {t("OLD_PASSWORD")}: <input type="password" name="oldPassword" id="oldPassword" required/>
                    </p>
                    <br/>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: t('PASSWORD_RULES')}} />
                    <br/><br/>
                    <p>
                        {t("PASSWORD")}: <input type="password" name="password" id="password" required/>
                    </p>
                    <p>
                        {t("PASSWORD_RPT")}: <input type="password" name="passwordRpt" id="passwordRpt" required/>
                    </p>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => doChangePassword() } value={t("CHANGE_PASSWORD")}/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['changePasswordPage', 'common'] )(ChangePasswordPage);
