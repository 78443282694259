/******************************************************************************
 *                                                                            *
 *  HomePage.js                                                               *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 01.10.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useTable } from "react-table";
import './HomePage.css'
import columns from "./homepage_columns.js";
import Context from "../context";
import { withTranslation } from 'react-i18next';

const HomePage = (props) => {
    const { t } = props;
    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({});
    const [showList, setShowList] = React.useState(null);
	var button = "";

    // Retrieve lists for current user
    const retrieveFoodLists = () => {
	    if ( Context.username !== "" ) {
	        Context.api.get('allFoodLists', {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
	            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
	                setData( res.data );
	            }
	        }).catch( ( error ) => {
				Context.alertError( error )
	        });
		}
    };

    useEffect(() => {
        retrieveFoodLists();
		document.title = "MyFoodList V1.0"
    }, [] );

	const editFoodList = (id) => {
        if ( id === 0 ) {
            setDlgData( { id: 0 } );
        } else {
            Context.api.get("foodList/"+ id, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( res => {
                setDlgData( res.data );
            }).catch( ( error ) => {
				Context.alertError( error )
            });
        }
	}
	
    const saveFoodList = () => {
        if ( !dlgData.name ) {
            alert( "Bitte einen Namen eintragen!");
            return;
        }
        Context.api.post("saveFoodList", dlgData, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( () => {
            setDlgData([]);
	        retrieveFoodLists();
        }).catch( ( error ) => {
			Context.alertError( error )
        });
    }
        
    const deleteFoodList = (id) => {
        if (window.confirm('Eintrag wirklich löschen?')) {
            Context.api.delete('deleteFoodList/'+ id, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( () => {
                setDlgData([]);
		        retrieveFoodLists();
            }).catch( ( error ) => {
				Context.alertError( error )
            });
        }
    }

    const showFoodList = (id) => {
  	    window.open(" ", 'foodlist='+ id, 'noopener,noreferrer')
    }

	var showButton = () => {
	    if ( Context.username === "" ) {
	        return <div></div>
	    } else {
	        return <input className='btn' type='image' src='/pics/new.png' title={t("CREATE_LIST")} alt={t("CREATE_LIST")} onClick={() => editFoodList(0)} />
		}
	}
	
	var showTable = () => {
	    if ( Context.username === "" ) {
	        return <div></div>
	    } else {
			return(
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => {
	                            if ((i === 1) || (i === 2)) {
    	                            return <th class='hide_me' {...column.getHeaderProps()}>{column.render("Header")}</th>
								} else {
    	                            return <th {...column.getHeaderProps()}>{column.render("Header")}</th>
								}
                            })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
	                            <tr {...row.getRowProps()} >
	                                {row.cells.map((cell,j) => {
	                                if ( j === row.cells.length- 1 ) {
	                                    return( 
	                                        <td {...cell.getCellProps()} >
	                                            <input className='btn' type='image' src='/pics/edit.png' title='Bearbeiten' alt='Bearbeiten' onClick={() => editFoodList(cell.value)} />
	                                            <input className='btn' type='image' src='/pics/delete.png' title='Löschen' alt='Löschen' onClick={() => deleteFoodList(cell.value)} />
	                                        </td>
	                                    );
	                                } else {
			                            if ((j === 1) || (j === 2)) {
		                                    return( <td title='Liste öffnen' class='hide_me cursor_pointer' {...cell.getCellProps()} onClick={e => {showFoodList(row.cells[row.cells.length- 1].value)}}>{cell.render("Cell")}</td>);
										} else {
		                                    return( <td title='Liste öffnen' class='cursor_pointer' {...cell.getCellProps()} onClick={e => {showFoodList(row.cells[row.cells.length- 1].value)}}>{cell.render("Cell")}</td>);
										}
	                                }
	                                })}
	                            </tr>
                            );
                        })}
                    </tbody>
                </table>
			);
		}
	}

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });
  
    return (
		<>
            {/* New/Edit-Dialog */}
            <Modal id='dlgEditFoodList' isOpen={dlgData.id != null}>
                Neue Lebensmittel-Liste
                <hr/>
                <br/>
                
                Name:<br/>
                <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/><br/>
				<br/>
				
                Beschreibung:<br/>
                <input type='text' maxlength='200' size='30' value={dlgData.description} onChange={e => setDlgData( Object.assign( {}, dlgData, {description : e.target.value}))}/><br/>
				<br/>

                Title:<br/>
                <input type='text' maxlength='200' size='30' value={dlgData.title} onChange={e => setDlgData( Object.assign( {}, dlgData, {title : e.target.value}))}/><br/>
				<br/>

				<label for='usecontainers'>Gefäße nutzen: </label>
                <input type='checkbox' id='usecontainers' checked={dlgData.usecontainers?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {usecontainers : e.target.checked}))}/><br/>
				<br/>

				<label for='usepictures'>Bilder nutzen: </label>
                <input type='checkbox' id='usepictures' checked={dlgData.usepictures?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {usepictures : e.target.checked}))}/><br/>
				<br/>

				<label for='usetotheday'>Tagesgenaue Haltbarkeitsdaten: </label>
                <input type='checkbox' id='usetotheday' checked={dlgData.usetotheday?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {usetotheday : e.target.checked}))}/><br/>
                <br/>

				<label for='usetotheday'>'Menge' nutzen: </label>
                <input type='checkbox' id='useamount' checked={dlgData.useamount?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {useamount : e.target.checked}))}/><br/>
                <br/>

				<label for='usetotheday'>'Datum hinzugefügt' nutzen: </label>
                <input type='checkbox' id='useaddeddate' checked={dlgData.useaddeddate?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {useaddeddate : e.target.checked}))}/><br/>
                <br/>

				<label for='usetotheday'>'Essfertig' nutzen: </label>
                <input type='checkbox' id='usereadytoeat' checked={dlgData.usereadytoeat?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {usereadytoeat : e.target.checked}))}/><br/>
                <br/>

                <input type='hidden' maxlength='500' value={dlgData.id} />
                <br/>
                
                <div className='dlgBtns'>
                    <input className='btn' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveFoodList()} />
                    <input className='btn' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({})} />
                </div>
            </Modal>

	        <div id="divLoginOuter" className="stddlg-outer">
	            <div id="divLogin" className="stddlg">
					{showButton()}
					{showTable()}
	                <div id="divHomepage" className="homepage">
	                    {!data.length && <div>{t("WELCOME")}</div>}
	                    <div><br/><br/>{t("WELCOME2")}</div>
	                </div>
	            </div>
	        </div>
		</>
    );
}

export default withTranslation(['homePage', 'common'] )(HomePage);
