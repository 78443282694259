/******************************************************************************
 *                                                                            *
 *  ProfilePage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 11.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, {useEffect} from "react";
import './ProfilePage.css'
import Context from "../context";
import { withTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import i18n from '../i18n';

const ProfilePage = (props) => {
    const { t } = props;

    const [data, setData] = React.useState({});

    useEffect( () => { 
        Context.api.get('retrieveUser/'+ Context.username, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( res => {
            if ( res.data.shownotifications === '' ) {
                res.data.shownotifications = "D";
            }
            res.data.currentUsername = Context.username;
            setData( res.data );
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }, []);

    const changeLanguage = (language) => {
        setData( Object.assign( {}, data, {language : language})  );
        Context.language = language;
        Cookies.set( 'language', language );
        i18n.changeLanguage( Context.language );
    };

    const doUpdateUser = () => {
        if ( data.username === "" ) {
            alert( t("ENTER_USERNAME"));
            return;
        }

        const email = data.email;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            alert( t("MAILFORMAT_INVALID") )
            return;
        }

        Context.api.post('updateUser', data, {
            auth: {
                username: Context.username,
                password: Context.password
            }} ).then( () => {
            alert( t("PROFILE_WAS_SAVED"))
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divRegisterOuter" className="stddlg-outer">
            <div id="divRegister" className="stddlg">
                <form >
                    <p>
                        {t("USERNAME")}: <input class="profilfeld" type="text" value={data.username} onChange={e => setData( Object.assign( {}, data, {username : e.target.value}))} required/>
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("MAIL")}: <input class="profilfeld" type="text" value={data.email} onChange={e => setData( Object.assign( {}, data, {email : e.target.value}))} required/>
                        <br/>
                        <br/>
                    </p>
                     <br/>
                    <label>{t("LANGUAGE")}:&nbsp;
                        <select name="language" id="language" size="1" value={data.language} onChange={e => changeLanguage(e.target.value)}>
                            <option value="de_de">Deutsch</option>
                            <option value="en_gb">English</option>
                            <option value="es_es">Español</option>
                            <option value="fr_fr">Français</option>
                        </select>
                    </label>
                    <br/>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => doUpdateUser() } value={t("SAVE_PROFILE")}/>
                    </p>
                </form>
                <br/>
            </div>
        </div>
    );
}

export default withTranslation(['profilePage', 'common'] )(ProfilePage);