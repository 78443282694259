/******************************************************************************
 *                                                                            *
 *  NewFoodPage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 19.12.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import columns from "./newfood_columns.js";
import "./NewFoodPage.css";
import FoodListPage from "../pages/FoodListPage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const NewFoodPage = (props) => {
	// ftid is only set if is called from the barcodescanner
    const {t, foodListId, fgid, ftid} = props;

    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({bestbefore : Date.now(), flid: foodListId});

    // Holds the containers for the dropdown-box
    const [containers, setContainers] = React.useState([]);

    // Holds info about the foodlist
    const [foodListData, setFoodListData] = React.useState([]);

    const retrieveFoodListData = () => {
        Context.api.get('foodList/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodListData)) {
                setFoodListData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    // Retrieve the containers for the dropdown-box
    const retrieveContainers = () => {
        Context.api.get('allContainers/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(containers)) {
                setContainers( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        })
    };

    useEffect(() => {
        retrieveFoodListData();
        retrieveContainers();
    }, [] );

    const saveFood = () => {
        Context.api.post("saveFood", dlgData, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
            showFoodListPage();
        }).catch( ( error ) => {
			Context.alertError( error )
        });
    }
        
    const showFoodListPage = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    // Retrieve the foodtemplates for the dropdown-box
    useEffect(() => {
        Context.api.get('foodTemplatesForGroup/' + fgid, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
                setData( res.data );
            }
			if (ftid) {
	        	setTimeout(document.getElementById(ftid).click(), 200);
			}
        }).catch( ( error ) => {
			Context.alertError( error )
        })
    }, [] );

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });

//alert(dlgData.bestbefore);
  
    return(
        <>
            {/* New/Edit-Dialog */}
            <Modal id='dlgAddFood' isOpen={dlgData.id != null}>
                <input className='btn btn2' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveFood()} />
                <input className='btn btn2' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({bestbefore : Date.now(), flid: foodListId})} />
                <br/>
                <br/>

                Bezeichnung:<br/>
                <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/>
				&nbsp;&nbsp;&nbsp;
                <br/>

				{foodListData.usecontainers &&
					<div>
	                    <label>Gefäß:<br/>
	                        <select size="1" value={dlgData.cid} onChange={e => setDlgData( Object.assign( {}, dlgData, {cid : e.target.value}))}>
	                            <option>-bitte auswählen-</option>;
	                            { containers.map((container,j) => {
	                                return <option value={container.id}>{container.name}</option>;
	                            })}
	                        </select>
	                    </label>
					</div>
				}
                <br/>

				{(foodListData.useamount === true) &&
                    <div>
						<label for='amount'>Menge: </label>
		                <input type='text' maxlength='20' size='20' id='amount' value={dlgData.amount} onChange={e => setDlgData( Object.assign( {}, dlgData, {amount : e.target.value}))}/><br/>
						<br/>
					</div>
				}
					
				{(foodListData.usereadytoeat === true) &&
                    <div>
						<label for='readytoeat'>Essfertig: </label>
		                <input type='checkbox' id='readytoeat' checked={(dlgData.readytoeat == "true")?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {readytoeat : e.target.checked}))}/><br/>
						<br/>
					</div>
				}

				Haltbar bis: <br/>
				{(foodListData.usetotheday === true)?
	                <DatePicker id='datePicker' startDate={Date.now()} dateFormat='dd.MM.yyyy' monthsShown='2' selected={dlgData.bestbefore} onChange={date => setDlgData( Object.assign( {}, dlgData, {bestbefore : date}))} />
					:
	                <DatePicker showMonthYearPicker id='datePicker' startDate={Date.now()} dateFormat='MM.yyyy' monthsShown='1' selected={dlgData.bestbefore} onChange={date => setDlgData( Object.assign( {}, dlgData, {bestbefore : date}))} />
				}
            </Modal>
            
	        <div id='divNewFoodTitle'>Neues Lebensmittel</div>
            <div id='divTable'>
                <div id='butnDiv'>
                    <div id='butnDivL'>
                    </div>
		            <div id='butnDivR'>
		                <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodListPage()} />
		            </div>
	            </div>
            
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column,i) => {
                                    if (( i !== 1 ) || (foodListData.usepictures === true)) {
                                    	return <th {...column.getHeaderProps()}>{column.render("Header")}</th>
									}
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row,i) => {
                            prepareRow(row);
                            return (
	                            <tr {...row.getRowProps()} id={data[i].id} onClick={e => {
	                                    let bestbefore = Date.now();
	                                    let shelftime = data[i].shelftime; 
										if (foodListData.usetotheday === true) {
		                                    bestbefore = bestbefore + shelftime * 86400000;
										} else {
		                                    bestbefore = bestbefore + shelftime * 86400000* 30;
										}
	                                    setDlgData( Object.assign( {}, dlgData, {id: 0, ftid : data[i].id, cid : data[i].cid, bestbefore : bestbefore, name: data[i].name, amount: data[i].amount, readytoeat: data[i].readytoeat }));
										setTimeout(()=> document.getElementById('datePicker').select());
	                                }}>
	                                {row.cells.map((cell,j) => {
	                                    if ( j === 1 ) {
											if (foodListData.usepictures === true) {
	                                            return(
	                                                <td {...cell.getCellProps()}> 
	                                                    <img height='60' alt='' src={"data:image/gif;base64," + cell.value} />
	                                                </td>
	                                            );
											}
	                                    } else {
	                                        return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
	                                    }
	
	                                })}
	                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default NewFoodPage;