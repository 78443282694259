/******************************************************************************
 *                                                                            *
 *  TemplatePage.js                                                           *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 23.12.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import columns from "./template_columns.js";
import "./TemplatePage.css";
import FoodListPage from "../pages/FoodListPage";

const TemplatePage = (props) => {
    const {t, foodListId} = props;

    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({});

    // Holds the foodgroups for the dropdown-box
    const [foodgroups, setFoodgroups] = React.useState([]);

    // Holds the containers for the dropdown-box
    const [containers, setContainers] = React.useState([]);

    // Holds info about the foodlist
    const [foodListData, setFoodListData] = React.useState([]);

    const retrieveFoodListData = () => {
        Context.api.get('foodList/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodListData)) {
                setFoodListData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    // Retrieve the foodgroups for the dropdown-box
    const retrieveFoodGroups = () => {
        Context.api.get('allFoodGroups/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodgroups)) {
                setFoodgroups( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        })
    };

    // Retrieve the containers for the dropdown-box
    const retrieveContainers = () => {
        Context.api.get('allContainers/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(containers)) {
                setContainers( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        })
    };

    const retrieveTemplates = () => {
        Context.api.get('allFoodTemplates/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
                setData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    useEffect(() => {
        retrieveFoodListData();
        retrieveFoodGroups();
        retrieveTemplates();
        retrieveContainers();
    }, [] );

    const editTemplate = (id) => {
        if ( id === 0 ) {
            setDlgData( { id: 0 } );
        } else {
            Context.api.get("foodTemplate/"+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
                res.data.picture = null;
                setDlgData( res.data );
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        }
    }
        
    const saveTemplate = () => {
        let formData = new FormData()
        formData.append('id', dlgData.id );
        formData.append('flid', foodListId )

        if ( !dlgData.name ) {
            alert( "Bitte eine Bezeichnung eintragen!");
            return;
        }
        formData.append('name', dlgData.name );

        if ( !dlgData.fgid ) {
            alert( "Bitte eine Lebensmittel-Gruppe auswählen!");
            return;
        }
        formData.append('fgid', dlgData.fgid );
        formData.append('cid', dlgData.cid );

        if ( !dlgData.shelftime ) {
            alert( "Bitte eine Haltbarkeit eintragen!");
            return;
        }
        formData.append('shelftime', dlgData.shelftime );
        formData.append('amount', dlgData.amount );
        formData.append('readytoeat', dlgData.readytoeat );
        formData.append('eancode', dlgData.eancode );

        if (dlgData.pictureData != null ) {
            formData.append('image', dlgData.pictureData )
            Context.api.post("saveFoodTemplate", formData, 
			{ headers: {
                'content-type': false
            }, auth: {
                username: Context.username,
                password: Context.password
	        }}).then( () => {
                retrieveTemplates();
                setDlgData({});
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        } else {
            Context.api.post("saveFoodTemplateWithoutImage", formData, { auth: {
                username: Context.username,
                password: Context.password
	        }}).then( () => {
                retrieveTemplates();
                setDlgData({});
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        }
    }
        
    const deleteTemplate = (id) => {
        if (window.confirm('Eintrag wirklich löschen?')) {
            Context.api.delete('deleteFoodTemplate/'+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
                retrieveTemplates();
                setDlgData({});
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        }
    }

    const showFoodPage = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });
  
    return(
        <>
            <div id='divTemplateTitle'>Templates</div>
            <div className='divTable'>
            
                {/* New/Edit-Dialog */}
                <Modal id='dlgEditTemplate' isOpen={dlgData.id != null}>
                    Neues Template
                    <hr/>
                    <br/>
                    
                    Bezeichnung:<br/>
                    <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/><br/>
                    <br/>

                    <label>Lebensmittel-Gruppe:<br/>
                        <select size="1" value={dlgData.fgid} onChange={e => setDlgData( Object.assign( {}, dlgData, {fgid : e.target.value}))}>
                            <option>-bitte auswählen-</option>;
                            { foodgroups.map((foodgroup,j) => {
                                return <option value={foodgroup.id}>{foodgroup.name}</option>;
                            })}
                        </select>
                    </label>
                    <br/>
                    <br/>

					{foodListData.usecontainers &&
	                    <div>
		                    <label>Gefäß:<br/>
		                        <select size="1" value={dlgData.cid} onChange={e => setDlgData( Object.assign( {}, dlgData, {cid : e.target.value}))}>
		                            <option>-bitte auswählen-</option>;
		                            { containers.map((container,j) => {
		                                return <option value={container.id}>{container.name}</option>;
		                            })}
		                        </select>
		                    </label>
		                    <br/>
		                    <br/>
	                    </div>
					}
					
					{(foodListData.usetotheday === true)?
						<span>Haltbarkeit in Tagen: </span>
						:
						<span>Haltbarkeit in Monaten: </span>
					}
                    <input type='text' maxlength='5' size='5' value={dlgData.shelftime} onChange={e => setDlgData( Object.assign( {}, dlgData, {shelftime : e.target.value}))}/><br/>
                    <br/>

					EAN-Code (Kommatrennung wenn mehrere):<br/>
                    <input type='text' maxlength='500' size='30' value={dlgData.eancode} onChange={e => setDlgData( Object.assign( {}, dlgData, {eancode : e.target.value}))}/><br/>
                    <br/>

					{(foodListData.usepictures === true) &&
	                    <div>
							Bild (wenn kein neues ausgewählt wird, bleibt das alte erhalten):<br/>
		                    <input type="file" value={dlgData.picture} accept="image/gif" onChange={e => {
		                        setDlgData( Object.assign( {}, dlgData, {picture : e.target.value}, {pictureData : e.target.files[0]}))}
		                    } />
						</div>
					}

					{(foodListData.useamount === true) &&
	                    <div>
							<label for='amount'>Menge: </label>
			                <input type='text' maxlength='20' size='20' id='amount' value={dlgData.amount} onChange={e => setDlgData( Object.assign( {}, dlgData, {amount : e.target.value}))}/><br/>
							<br/>
						</div>
					}
						
					{(foodListData.usereadytoeat === true) &&
	                    <div>
							<label for='readytoeat'>Essfertig: </label>
			                <input type='checkbox' id='readytoeat' checked={(dlgData.readytoeat === true)?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {readytoeat : e.target.checked}))}/><br/>
							<br/>
						</div>
					}
						
                    <br/>
                    <input type='hidden' maxlength='500' value={dlgData.id} />
                    <br/>
                    
                    <div className='dlgBtns'>
                        <input className='btn' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveTemplate()} />
                        <input className='btn' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({})} />
                    </div>
                </Modal>
                
                <div id='butnDiv'>
                    <div id='butnDivL'>
                        <input className='btn' type='image' src='/pics/new.png' title='Neues Template' alt='Neues Template' onClick={() => editTemplate(0)} />
                    </div>
                    <div id='butnDivR'>
                        <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodPage()} />
                    </div>
                </div>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,i) => {
                                if ( i === 1 ) {
									if (foodListData.usereadytoeat === true) {
	                                  	return( <th {...column.getHeaderProps()}><span dangerouslySetInnerHTML={{__html: column.render("Header")}} /></th> );
									}
                                } else if ( i === 3 ) {
									if (foodListData.usecontainers === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else if ( i === 4 ) {
									if (foodListData.usetotheday === true) {
                                    	return( <th {...column.getHeaderProps()}>Haltbarkeit in Tagen</th> );
									} else {
                                    	return( <th {...column.getHeaderProps()}>Haltbarkeit in Monaten</th> );
									}
                                } else if ( i === 5 ) {
									if (foodListData.useamount === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else if ( i === 6 ) {
									if (foodListData.usepictures === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else {
                                    return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
                                }
                            })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell,j) => {
	                                if ( j === 1 ) {
										if (foodListData.usereadytoeat === true) {
		                                    return( 
		                                        <td {...cell.getCellProps()}>
													{(cell.value == "true") && <img width="16" src="/pics/haken.png" title="Essfertig" alt="Essfertig" />}
		                                        </td>
		                                    );
										}                                    
	                                } else if ( j === 3 ) {
										if (foodListData.usecontainers === true) {
		                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
										}                                    
	                                } else if ( j === 5 ) {
										if (foodListData.useamount === true) {
		                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
										}
	                                } else if ( j === 6 ) {
										if (foodListData.usepictures === true) {
		                                    return(
		                                        <td {...cell.getCellProps()}>
		                                            <img height='60' alt='' src={"data:image/gif;base64," + cell.value} />
		                                        </td>
		                                    );                                    
										}
	                                } else if ( j === 7 ) { // EAN-Code
	                                    return( <td className='tdNotTooLarge' {...cell.getCellProps()}><div className='notTooLarge'>{cell.render("Cell")}</div></td>);
	                                } else if ( j === row.cells.length- 1 ) {
	                                    return( 
	                                        <td {...cell.getCellProps()}>
	                                            <input className='btn' type='image' src='/pics/edit.png' title='Bearbeiten' alt='Bearbeiten' onClick={() => editTemplate(cell.value)} />
	                                            <input className='btn' type='image' src='/pics/delete.png' title='Löschen' alt='Löschen' onClick={() => deleteTemplate(cell.value)} />
	                                        </td>
	                                    );
	                                } else {
	                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
	                                }
                                })}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TemplatePage;