const columns = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Beschreibung',
        accessor: 'description'
    },
    {
        Header: 'Title',
        accessor: 'title'
    },
    {
        Header: '',
        accessor: 'id'
    }
]
export default columns;