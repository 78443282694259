/******************************************************************************
 *                                                                            *
 *  ContainerPage.js                                                          *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 21.09.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import columns from "./container_columns.js";
import "./ContainerPage.css";
import FoodListPage from "../pages/FoodListPage";

const ContainerPage = (props) => {
    const {t, foodListId} = props;

    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({});

    // Holds info about the foodlist
    const [foodListData, setFoodListData] = React.useState([]);

    const retrieveFoodListData = () => {
        Context.api.get('foodList/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodListData)) {
                setFoodListData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    const retrieveContainers = () => {
        Context.api.get('allContainers/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
                setData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    useEffect(() => {
        retrieveFoodListData();
        retrieveContainers();
    }, [] );

    const editContainer = (id) => {
        if ( id === 0 ) {
            setDlgData( { id: 0 } );
        } else {
            Context.api.get("container/"+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
                res.data.picture = null;
                setDlgData( res.data );
            }).catch( ( error ) => {
                Context.alertError( error );
            });
        }
    }
        
    const saveContainer = () => {
        let formData = new FormData()
        formData.append('id', dlgData.id )
        formData.append('flid', foodListId )

        if ( !dlgData.name ) {
            alert( "Bitte eine Bezeichnung eintragen!");
            return;
        }
        formData.append('name', dlgData.name )

        Context.api.post("saveContainer", formData, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( () => {
            retrieveContainers();
            setDlgData({});
        }).catch( ( error ) => {
			Context.alertError( error );
        });
    }
        
    const deleteContainer = (id) => {
        if (window.confirm('Eintrag wirklich löschen?')) {
            Context.api.delete('deleteContainer/'+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
                retrieveContainers();
                setDlgData({});
            }).catch( ( error ) => {
                Context.alertError( error );
            });
        }
    }

    const showFoodPage = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });
  
    return(
        <>
            <div id='divContainerTitle'>Gefäße</div>
            <div className='divContainerTable'>
            
                {/* New/Edit-Dialog */}
                <Modal id='dlgEditContainer' isOpen={dlgData.id != null}>
                    Neues Gefäß
                    <hr/>
                    <br/>
                    
                    Bezeichnung:<br/>
                    <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/><br/>
                    <br/>
                    <input type='hidden' maxlength='500' value={dlgData.id} />
                    <br/>
                    
                    <div className='dlgBtns'>
                        <input className='btn' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveContainer()} />
                        <input className='btn' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({})} />
                    </div>
                </Modal>
                
                <div id='butnDiv'>
                    <div id='butnDivL'>
                        <input className='btn' type='image' src='/pics/new.png' title='Neue Gruppe' alt='Neue Gruppe' onClick={() => editContainer(0)} />
                    </div>
                    <div id='butnDivR'>
                        <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodPage()} />
                    </div>
                </div>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell,j) => {
                                if ( j === row.cells.length- 1 ) {
                                    return( 
                                        <td {...cell.getCellProps()}>
                                            <input className='btn' type='image' src='/pics/edit.png' title='Bearbeiten' alt='Bearbeiten' onClick={() => editContainer(cell.value)} />
                                            <input className='btn' type='image' src='/pics/delete.png' title='Löschen' alt='Löschen' onClick={() => deleteContainer(cell.value)} />
                                        </td>
                                    );
                                } else {
                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
                                }
                                })}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ContainerPage;