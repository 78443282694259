/******************************************************************************
 *                                                                            *
 *  FoodListPage.js                                                           *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 19.12.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect, Suspense } from "react";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import { useTable } from "react-table";
import Context from "../context";
import columns from "./foodlist_columns.js";
import "./FoodListPage.css";
import ContainerPage from "../pages/ContainerPage";
import TemplatePage from "../pages/TemplatePage";
import GroupPage from "../pages/GroupPage";
import BarcodeScannerPage from "../pages/BarcodeScannerPage";
import NewFoodGroupSelectionPage from "../pages/NewFoodGroupSelectionPage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FoodListPage = (props) => {
    const {t, foodListId} = props;

    const [data, setData] = React.useState([]);
    const [dlgData, setDlgData] = React.useState({});

    // Holds info about the foodlist
    const [foodListData, setFoodListData] = React.useState([]);

    // Holds the foodtemplates for the dropdown-box
    const [foodtemplates, setFoodtemplates] = React.useState([]);

    // Holds the containers for the dropdown-box
    const [containers, setContainers] = React.useState([]);

    // Retrieve the containers for the dropdown-box
    const retrieveContainers = () => {
        Context.api.get('allContainers/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(containers)) {
                setContainers( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        })
    };

    const retrieveFoodListData = () => {
        Context.api.get('foodList/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodListData)) {
                setFoodListData( res.data );
				document.title = (res.data.title != "") ? res.data.title : res.data.name;
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    const retrieveFoods = () => {
        Context.api.get('allFoods/' + foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(data)) {
                setData( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    };

    useEffect(() => {
        retrieveFoodListData();
        retrieveFoods();
        retrieveContainers();
    }, [] );

    const editFood = (id) => {
        if ( id === 0 ) {
            setDlgData( { id: 0, bestbefore : Date.now()} );
        } else {
            Context.api.get("food/"+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
                res.data.bestbefore= new Date(res.data.bestbefore).getTime();
                Object.assign( res.data, { flagOpen: false });
                setDlgData( res.data );
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        }
    }
        
    const openFood = (id) => {
        Context.api.get("food/"+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            Object.assign( res.data, { flagOpen: true, daysLeft: 14, name: res.data.name + ' (geöffnet)' });
            setDlgData( res.data );
            setTimeout(()=> document.getElementById('textDaysLeft').select());
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }
        
    const saveFood = () => {
        if ( !dlgData.name ) {
            alert( "Bitte eine Bezeichnung eintragen!");
            return;
        }

        // If foodOpen was choosed, then compute the best-before-date
        if ( dlgData.daysLeft ) {
            let bestbefore = Date.now();
            let shelftime = dlgData.daysLeft; 
            bestbefore = bestbefore + shelftime * 86400000;
            dlgData.bestbefore = bestbefore; 
        }
        Context.api.post("saveFood", dlgData, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
            retrieveFoods();
            setDlgData({});
        }).catch( ( error, ) => {
            Context.alertError( error );
        });
    }
        
    const deleteFood = (id) => {
        if (window.confirm('Eintrag wirklich löschen?')) {
            Context.api.delete('deleteFood/'+ id, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( () => {
                retrieveFoods();
                setDlgData({});
            }).catch( ( error ) => {
	            Context.alertError( error );
            });
        }
    }

    const showTemplatePage = () => {
        ReactDOM.render( <TemplatePage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showGroupPage = () => {
        ReactDOM.render( <GroupPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showContainerPage = () => {
        ReactDOM.render( <ContainerPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showNewFoodGroupSelectionPage = () => {
        ReactDOM.render( <NewFoodGroupSelectionPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showBarcodescanner = () => {
        ReactDOM.render( <Suspense fallback="loading"><BarcodeScannerPage foodListId={foodListId}/></Suspense>, document.getElementById('pageArea'));
    }

    // Retrieve the foodtemplates for the dropdown-box
    useEffect(() => {
        Context.api.get('allFoodTemplates/'+ foodListId, {
	            auth: {
	                username: Context.username,
	                password: Context.password
	            }}).then( res => {
            if (JSON.stringify(res.data) !== JSON.stringify(foodtemplates)) {
                setFoodtemplates( res.data );
            }
        }).catch( ( error ) => {
            Context.alertError( error );
        })
    }, [] );

    var {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });
  
    return(
        <>
            {/* New/Edit-Dialog */}
            <Modal id='dlgEditFood' isOpen={(dlgData.id != null) && (dlgData.flagOpen === false )}>
                Bezeichnung:<br/>
                <input type='text' maxlength='200' size='30' value={dlgData.name} onChange={e => setDlgData( Object.assign( {}, dlgData, {name : e.target.value}))}/>
				&nbsp;&nbsp;&nbsp;

                <input className='btn btn2' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveFood()} />
                <input className='btn btn2' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({bestbefore : Date.now(), flid: foodListId})} />
                <br/>
                <br/>

				{foodListData.usecontainers &&
					<div>
	                    <label>Gefäß:<br/>
	                        <select size="1" value={dlgData.cid} onChange={e => setDlgData( Object.assign( {}, dlgData, {cid : e.target.value}))}>
	                            <option>-bitte auswählen-</option>;
	                            { containers.map((container,j) => {
	                                return <option value={container.id}>{container.name}</option>;
	                            })}
	                        </select>
	                    </label>
					</div>
				}
                <br/>

				{(foodListData.useamount === true) &&
                    <div>
						<label for='amount'>Menge: </label>
		                <input type='text' maxlength='20' size='20' id='amount' value={dlgData.amount} onChange={e => setDlgData( Object.assign( {}, dlgData, {amount : e.target.value}))}/><br/>
						<br/>
					</div>
				}
					
				{(foodListData.usereadytoeat === true) &&
                    <div>
						<label for='readytoeat'>Essfertig: </label>
		                <input type='checkbox' id='readytoeat' checked={(dlgData.readytoeat === true)?'checked':''} onChange={e => setDlgData( Object.assign( {}, dlgData, {readytoeat : e.target.checked}))}/><br/>
						<br/>
					</div>
				}

				Haltbar bis: <br/>
				{(foodListData.usetotheday === true)?
	                <DatePicker id='datePicker' startDate={Date.now()} dateFormat='dd.MM.yyyy' monthsShown='2' selected={dlgData.bestbefore} onChange={date => setDlgData( Object.assign( {}, dlgData, {bestbefore : date}))} />
					:
	                <DatePicker showMonthYearPicker id='datePicker' startDate={Date.now()} dateFormat='MM.yyyy' monthsShown='1' selected={dlgData.bestbefore} onChange={date => setDlgData( Object.assign( {}, dlgData, {bestbefore : date}))} />
				}
            </Modal>

            <Modal id='dlgOpenFood' isOpen={(dlgData.id != null) && (dlgData.flagOpen === true )}>
                Verpackung öffnen
                <hr/>
                <br/>
                
                Innerhalb wie vielen Tagen muss das Lebensmittel verbraucht werden?<br/>
                <br/>
                <input id='textDaysLeft' type='text' maxlength='5' size='5' Value={dlgData.daysLeft} onChange={e => setDlgData( Object.assign( {}, dlgData, {daysLeft : e.target.value}))}/><br/>
                <br/>

                <input type='hidden' maxlength='500' value={dlgData.id} />
                
                <div className='dlgBtns'>
                    <input className='btn' type='image' src='/pics/ok.png' title='Ok' alt='Ok' onClick={() => saveFood()} />
                    <input className='btn' type='image' src='/pics/delete.png' title='Abbrechen' alt='Abbrechen' onClick={() => setDlgData({})} />
                </div>
            </Modal>
            
            <div id='divFoodListTitle'>{foodListData.title}</div>
            <div className='divTable'>
                <div id='butnDiv'>
                    <div id='butnDivL'>
                        <input className='btn' type='image' src='/pics/new.png' title='Neuer Eintrag' alt='Neuer Eintrag' onClick={() => showNewFoodGroupSelectionPage(0)} />
                        <input className='btn' type='image' src='/pics/newbarcode.png' title='Neuer Eintrag mit Barcodescanner' alt='Neuer Eintrag mit Barcodescanner' onClick={() => showBarcodescanner(0)} />
                    </div>
                    <div id='butnDivR'>
                        <input className='btn' type='image' src='/pics/templates.png' title='Templates' alt='Templates' onClick={() => showTemplatePage()} />
                        <input className='btn' type='image' src='/pics/groups.png' title='Gruppen' alt='Gruppen' onClick={() => showGroupPage()} />
						{foodListData.usecontainers && <input className='btn' type='image' src='/pics/container.png' title='Container' alt='Container' onClick={() => showContainerPage()} />}
                    </div>
                </div>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,i) => {
                                if ( i === 0 ) {
                                    return( <th {...column.getHeaderProps()} className='markerColumn'>{column.render("Header")}</th> );
                                } else if ( i === 2 ) {
									if (foodListData.usereadytoeat === true) {
	                                  	return( <th {...column.getHeaderProps()}><span dangerouslySetInnerHTML={{__html: column.render("Header")}} /></th> );
									}
                                } else if ( i === 3 ) {
									if (foodListData.usecontainers === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else if ( i === 5 ) {
									if (foodListData.useamount === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else if ( i === 6 ) {
									if (foodListData.usepictures === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else if ( i === 7 ) {
									if (foodListData.useaddeddate === true) {
                                    	return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
									}
                                } else {
                                    return( <th {...column.getHeaderProps()}>{column.render("Header")}</th> );
                                }
                            })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell,j) => {
                                if ( j === 0 ) {
                                    let marker = "";
                                    if ( cell.value === "OK" ) {
                                        marker = <img className='marker' src='/pics/yellow.png' alt='OK' /> 
                                    } else if ( cell.value === "LANG" ) {
                                        marker = <img className='marker' src='/pics/green.png' alt='LANG' />
                                    } else if ( cell.value === "BALD" ) {
                                        marker = <img className='marker' src='/pics/red.png' alt='BALD' />
                                    } else {
                                        marker = <img class='marker blink' src='/pics/red.png' alt='ABGELAUFEN' />
                                    }
                                    return(
                                        <td {...cell.getCellProps()} className='tdWithoutMargin'>
                                            {marker}
                                        </td>
                                    );
                                } else if ( j === 2 ) {
									if (foodListData.usereadytoeat === true) {
	                                    return( 
	                                        <td {...cell.getCellProps()}>
												{cell.value && <img width="16" src="/pics/haken.png" title="Essfertig" alt="Essfertig" />}
	                                        </td>
	                                    );
									}
                                } else if ( j === 3 ) {
									if (foodListData.usecontainers === true) {
	                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
									}
                                } else if ( j === 4 ) {
									// Haltbar bis
                                    let date = cell.value;
									if (foodListData.usetotheday === true) {
	                                    date = date.substring(8,10) + "." + date.substring(5,7) + "." + date.substring(0, 4); 
									} else {
	                                    date = date.substring(5,7) + " / " + date.substring(0, 4); 
									}
                                    return( <td {...cell.getCellProps()}>{date}</td>);
                                } else if ( j === 5 ) {
									if (foodListData.useamount === true) {
	                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
									}
                                } else if ( j === 6 ) {
								    if (foodListData.usepictures === true) {
                                        return(
                                            <td {...cell.getCellProps()} className='tdWithoutMargin'> 
                                                <img height='38' alt='' src={"data:image/gif;base64," + cell.value} />
                                            </td>
                                        );
                                    }   
                                } else if ( j === 7 ) {
									if (foodListData.useaddeddate === true) {
	                                    let date = cell.value;
										if (foodListData.usetotheday === true) {
		                                    date = date.substring(8,10) + "." + date.substring(5,7) + "." + date.substring(0, 4); 
										} else {
		                                    date = date.substring(5,7) + " / " + date.substring(0, 4); 
										}
	                                    return( <td {...cell.getCellProps()}>{date}</td>);
									}
                                } else if ( j === row.cells.length- 1 ) {
                                    return( 
                                        <td {...cell.getCellProps()}>
                                            <input className='btnSmall' type='image' src='/pics/tupper.png' title='Anbrechen' alt='Anbrechen' onClick={() => openFood(cell.value)} />
                                            <input className='btnSmall' type='image' src='/pics/edit.png' title='Bearbeiten' alt='Bearbeiten' onClick={() => editFood(cell.value)} />
                                            <input className='btnSmall' type='image' src='/pics/delete.png' title='Löschen' alt='Löschen' onClick={() => deleteFood(cell.value)} />
                                        </td>
                                    );
                                } else {
                                    return( <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);
                                }
                                })}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FoodListPage;