/******************************************************************************
 *                                                                            *
 *  BarcodeScannerPage.js                                                     *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 22.12.2021                                                     *
 *                                                                            *
 *  (C) 2021 Martin Wolf IT-Consulting (www.wolf-itc.de)                      *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect, Component } from "react";
import ReactDOM from 'react-dom';
import Context from "../context";
import TemplatePage from "../pages/TemplatePage";
import NewFoodPage from "../pages/NewFoodPage";
import FoodListPage from "../pages/FoodListPage";
import './BarcodeScannerPage.css'
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
//import BarcodeScannerComponent from "react-qr-barcode-scanner";


const BarcodeScannerPage = (props) => {
    const {t, foodListId, fgid} = props;
    const [data, setData] = React.useState([{focusDistance: 0.1, sharpness: 10.0, focusMode: 'continuous', imageSmoothing: false, facingMode: 'environment', width: 8000}]);

    const showNewFoodDialog = (eanCode) => {
		// Try to find a template for the EANCode
        Context.api.get('foodTemplateByEANCode/'+ foodListId+ "/" + eanCode, {
            auth: {
                username: Context.username,
                password: Context.password
        }}).then( res => {
			showNewFoodListPage(res.data.id, res.data.fgid);
        }).catch( ( error ) => {
			// If the EANCode is not in the DB, then open a new Template
            showTemplatePage();
        });
	}
	
    const showNewFoodListPage = (ftid, fgid) => {
        ReactDOM.render( <NewFoodPage fgid={fgid} foodListId={foodListId} ftid={ftid}/>, document.getElementById('pageArea'));
    }

    const showTemplatePage = () => {
        ReactDOM.render( <TemplatePage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const showFoodPage = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

    const toggle = () => {
        ReactDOM.render( <FoodListPage foodListId={foodListId}/>, document.getElementById('pageArea'));
    }

	const d1 = () => {
		document.getElementById('btn8000').click();
	}

    useEffect(() => {
        setTimeout(d1, 1000);
    }, [] ); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id='divBCS'>
            <div id='butnDiv'>
                <div id='butnDivL'>
                </div>
                <div id='butnDivR'>
                    <input className='btn' type='image' src='/pics/back.png' title='zurück' alt='zurück' onClick={() => showFoodPage()} />
                </div>
            </div>
            <input id='btn8000' className='bitteAbstand' type="button" onClick={ () => setData({focusDistance: 0.1, sharpness: 10.0, focusMode: 'continuous', imageSmoothing: false, facingMode: 'environment', width: 8000}) } value="Env 8000"/>
            <input id='btn2000' className='bitteAbstand' type="button" onClick={ () => setData({focusDistance: 0.1, sharpness: 10.0, focusMode: 'continuous', imageSmoothing: false, facingMode: 'environment', width: 1280}) } value="Env 1280"/>
            <input className='bitteAbstand' type="button" onClick={ () => setData({focusDistance: 0.1, sharpness: 10.0, focusMode: 'continuous', imageSmoothing: false, facingMode: 'user', width: 1280}) } value="Front 1280"/>
{/*
			<BarcodeScannerComponent
			    videoConstraints={data}
	        	onUpdate={(err, result) => {
			        if (result) {
						showNewFoodDialog(result.text);
			        }}
	        	}
	      	/>
*/}
       	</div>
    );
}

export default withTranslation(['barcodeScannerPage', 'common'] )(BarcodeScannerPage);
