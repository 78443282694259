/******************************************************************************
 *                                                                            *
 *  App.js                                                                    *
 *                                                                            *
 *  Version  : 2                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 15.09.2021                                                     *
 *  modified : 20.06.2024 2 mw Corrected frontend URL                         *
 *                                                                            *
 *  (C) 2021.2024 Martin Wolf IT-Consulting (www.wolf-itc.de)                 *
 *                                                                            *
 * ***************************************************************************/
import React, { Suspense, useEffect } from "react";
import Modal from 'react-modal';
import Navbar from "./Navbar";
import './App.css';
import Cookies from 'js-cookie';
import Context from "../context";
import HomePage from "../pages/HomePage";
import FoodListPage from "../pages/FoodListPage";
import axios from "axios";
import i18n from '../i18n';
import {withTranslation} from "react-i18next";

Modal.setAppElement('#root');
const App = (props) => {
    const { t } = props;

    // Set hostnames for front- and backend
    if ( Context.backendURL === "" ) {
        var hostname = window.location.hostname;
        Context.backendURL = 'https://'+ hostname+ ':8095/';
        Context.frontendURL = 'https://myfoodlist.ddnss.de:3095/';
        Context.api = axios.create({
            baseURL: Context.backendURL
        })
    };

    // Fetch user from cookie if present
    var user = Cookies.get( 'user' );
    if ( user && ( user !== "" )) {
        if ( Context.username !== user) {
            Context.username = user;
            Context.password = Context.decrypt( Cookies.get( 'pw' ));
            Context.language = Cookies.get( 'language' );
            i18n.changeLanguage( Context.language );
        }
    } else {
        var lng = navigator.language.toLowerCase().replaceAll( "-", "_");
        switch ( lng ) {
            case "de" : lng = "de_de"; break;
            case "en" : lng = "en_gb"; break;
            case "en_us" : lng = "en_gb"; break;
            case "es" : lng = "es_es"; break;
            case "fr" : lng = "fr_fr"; break;
            default:
        }
        if ( Context.language !== lng) {
            Context.language = lng;
            i18n.changeLanguage( Context.language );
        }
    }

    // If this is a call for an account-activation, then retrieve key and activate the account
    if ( "/activateAccount" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('activateAccount', o ).then( () => {
            alert( i18n.t("common:ACCOUNT_WAS_ACTIVATED") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for a password-reset, then retrieve key and show reset-message
    if ( "/resetPassword" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('resetPassword', o ).then( res => {
            alert( i18n.t("common:PASSWORD_WAS_RESET_TO")+ ":\n\n"+ res.data+ "\n\n"+ i18n.t("common:START_MESSAGE") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for an account-deletion, then retrieve key and show deletion-message
    if ( "/deleteAccount" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('deleteAccount', o ).then( () => {
            Cookies.set( 'user', '' );
            Cookies.set( 'pw', '' );
            Context.username = '';
            Context.password = '';
            alert( i18n.t("common:ACCOUNT_HAS_BEEN_DELETED"));
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

	var pageToShow = <HomePage />
    if (window.name.includes("foodlist=")) {
		pageToShow = <FoodListPage foodListId={window.name.substring(window.name.indexOf("=") + 1)} />
	}
		
    return (
        <div className="App">
            <img className="imgTitle" alt={t("IMG_TITLE")} src={process.env.PUBLIC_URL + '/headerpic.jpg'}/>
            <div id="divNavbar">
                <Navbar />
            </div>
            <div id="pageArea">
                {pageToShow}
            </div>
        </div>
    );
}

export default withTranslation('common')(App);
